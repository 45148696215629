import * as z from 'zod';
import { obligatedFieldsCompanyFormSchema, obligatedFieldsSchema } from './CompanyFormSchema';
import { companyUserFormSchema } from './CompanyUserFormSchema';
import { companyBillingFormSchema } from './CompanyBillingFormSchema';

export const claimFormSchema = obligatedFieldsSchema.merge(companyUserFormSchema);
export const registrationFormSchema = obligatedFieldsCompanyFormSchema.merge(companyUserFormSchema);

export const registrationFormSchemaWithBillingInfo = registrationFormSchema.merge(
  companyBillingFormSchema
);

export type RegistrationFormData = z.infer<typeof registrationFormSchemaWithBillingInfo>;
