import * as z from 'zod';
import { isValidEmail, isValidPhoneNumber, isBelgianPostalCode } from './helpers';

export const companyContactSchema = z.object({
  description: z.string().nonempty('shared.form.validation.nonEmpty'),
  name: z.string().nonempty('shared.form.validation.nonEmpty'),
  street: z.string().nonempty('shared.form.validation.nonEmpty'),
  number: z.string().nonempty('shared.form.validation.nonEmpty'),
  postal: z.string().refine(isBelgianPostalCode, {
    message: 'shared.form.validation.belgianPostalCode',
  }),
  city: z.string().nonempty('shared.form.validation.nonEmpty'),
  email: z.string().refine(isValidEmail, {
    message: 'shared.form.validation.invalidEmail',
  }),
  phone: z.string().refine(isValidPhoneNumber, {
    message: 'shared.form.validation.invalidPhoneNumber',
  }),
});

export type CompanyContactFormData = z.infer<typeof companyContactSchema>;
