import * as z from 'zod';
import { isValidEmail } from './helpers';

export const writeReviewFormSchema = z.object({
  description: z.string().min(1, 'shared.form.validation.nonEmpty'),
  screenName: z.string().min(1, 'shared.form.validation.nonEmpty'),
  email: z.string().refine(isValidEmail, {
    message: 'shared.form.validation.invalidEmail',
  }),
  postalCode: z.string().optional(),
  placeId: z.string().min(1, 'shared.form.validation.nonEmpty'),
  location: z.string().min(1, 'shared.form.validation.nonEmpty'),
  collaberation: z.string().min(1, 'shared.form.validation.nonEmpty'),
  title: z.string().optional(),
  acceptPolicy: z.boolean().refine((val) => val, {
    message: 'shared.form.validation.nonEmpty',
  }),
});
export type WriteReviewFormData = z.infer<typeof writeReviewFormSchema>;
