import { FocusEvent, useState } from 'react';
import { isValidBelgianVatNumber, isValidDutchVatNumber } from './form-validation';

export const useTavNumberBlur = () => {
  const [tavNumber, setTavNumber] = useState('');
  const [tavNumberIsValid, setTavNumberIsValid] = useState(false);

  const handleTavNumberBlur = (e: FocusEvent<HTMLInputElement>, country: 'NL' | 'BE' = 'BE') => {
    if (e.target.value !== tavNumber) {
      setTavNumber(e.target.value);
      const isValid =
        country === 'BE'
          ? isValidBelgianVatNumber(e.target.value)
          : isValidDutchVatNumber(e.target.value);
      setTavNumberIsValid(isValid);
    }
  };

  const resetTavNumberBlur = () => {
    setTavNumber('');
  };

  return {
    handleTavNumberBlur,
    tavNumber,
    tavNumberIsValid,
    resetTavNumberBlur,
  };
};
