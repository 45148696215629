import * as z from 'zod';
import { isValidEmail } from './helpers';

export const AddEmployeeFormSchema = z.object({
  email: z.string().refine(isValidEmail, {
    message: 'shared.form.validation.invalidEmail',
  }),
  firstName: z.string().nonempty('shared.form.validation.nonEmpty'),
  name: z.string().nonempty('shared.form.validation.nonEmpty'),
});

export type AddEmployeeFormData = z.infer<typeof AddEmployeeFormSchema>;
