import * as z from 'zod';
import {
  isValidVatNumber,
  isValidEmail,
  optionalValidPhoneNumber,
  optionalValidUrl,
} from './helpers';
export const companyGeneralInfoFormSchema = z.object({
  name: z.string().nonempty('shared.form.validation.nonEmpty'),
  phone: optionalValidPhoneNumber(),
  email: z.string().refine(isValidEmail, {
    message: 'shared.form.validation.invalidEmail',
  }),
  street: z.string().nonempty('shared.form.validation.nonEmpty'),
  streetNr: z.string().nonempty('shared.form.validation.nonEmpty'),
  postalCode: z.string().nonempty('shared.form.validation.nonEmpty'),
  city: z.string().nonempty('shared.form.validation.nonEmpty'),
  url: optionalValidUrl(),
});

export const companySubscriptionFormSchema = z.object({
  country: z.string().nonempty('shared.form.validation.nonEmpty'),
  name: z.string().nonempty('shared.form.validation.nonEmpty'),
  email: z.string().refine(isValidEmail, {
    message: 'shared.form.validation.invalidEmail',
  }),
  vat: z.string().refine(isValidVatNumber, {
    message: 'shared.form.validation.invalidVatNumber',
  }),
  street: z.string().nonempty('shared.form.validation.nonEmpty'),
  streetNr: z.string().nonempty('shared.form.validation.nonEmpty'),
  postalCode: z
    .string()
    .nonempty('shared.form.validation.nonEmpty')
    .min(4, 'shared.form.validation.nonEmpty'),
  city: z.string().nonempty('shared.form.validation.nonEmpty'),
});
